import React from 'react';
import Table from 'emerald-ui/lib/Table';

const DetailsModal = ({ candidate = {}, isRegistered = false }) => {
  let { middleName = '', firstName = '', lastName = '', email = '' } = candidate;

  let candidateFullname = `${firstName} ${middleName} ${lastName}`.replace(/ +/g, ' ').trim();
  let note;

  if (isRegistered) {
    note = 'The candidate completed his wallet registration ';
  } else {
    note = "The candidate hasn't completed his wallet registration yet";
  }

  return (
    <Table style={{ margin: '20px 0' }}>
      <tbody>
        <tr>
          <td>Cadidate Name</td>
          <td>{candidateFullname}</td>
        </tr>
        <tr>
          <td>
            Cadidate registration <b>email</b>
          </td>
          <td>{email || '-'}</td>
        </tr>
        <tr>
          <td>Note</td>
          <td>{note}</td>
        </tr>
      </tbody>
    </Table>
  );
};

//Puede estar account status unverified con accessCode status created, que significa que no ha hecho nada (edited)
//Y a veces el accessCode se pone revoked, que es cuando le dan otra cuenta al usuario y ese code se invalida

export default DetailsModal;
