import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Button from 'emerald-ui/lib/Button';
import Modal from 'emerald-ui/lib/Modal';
import Icon from 'emerald-ui/lib/Icon';

import everCheckLogo from './evercheck-logo.svg';
import { canUse } from '../../utils/userPermissionHandler';

import './Sidebar.css';

const MenuItem = ({ path, onClick, icon, title, iconColor }) => {
  let location = useLocation();

  const active = location.pathname === path;
  return (
    <Link to={path}>
      <Button
        shape={active ? 'classic' : 'flat'}
        className="ec-monitoring-sidebar-btn"
        onClick={onClick}
        color={active ? 'info' : 'default'}
      >
        <Icon name={icon} style={{ color: iconColor || 'auto' }} />
        <span className={!active ? 'text-light' : ''}>{title}</span>
      </Button>
    </Link>
  );
};

const sideBar = ({ show, closeSidebar }) => {
  return (
    <Modal
      className="left-drawer ec-monitoring-sidebar"
      show={show}
      onHide={closeSidebar}
      style={{ fontSize: '20px' }}
      hideOnOutsideClick
    >
      <Modal.Header className="ec-monitoring-sidebar-header">
        <img src={everCheckLogo} alt="logo" />
      </Modal.Header>

      <div className="ec-monitoring-title-menu">License Verification</div>
      <Modal.Body>
        {canUse('STATE_STATS') && (
          <MenuItem path="/lv/state-stats" title="State Status" icon="check" onClick={closeSidebar} />
        )}
        {canUse('FILE_STATS') && (
          <MenuItem path="/lv/file-stats" title="File Status" icon="insert_drive_file" onClick={closeSidebar} active />
        )}
        {canUse('LICENSE_UPLOAD_PROGRESS') && (
          <MenuItem
            path="/lv/license-uploads"
            title="License Uploads Status"
            icon="insert_drive_file"
            onClick={closeSidebar}
          />
        )}
        {canUse('LICENSE_EXPORT_PROGRESS') && (
          <MenuItem path="/lv/export-stats" title="Exports Status" icon="insert_drive_file" onClick={closeSidebar} />
        )}
        {canUse('EXPIRING_LICENSES') && (
          <MenuItem
            path="/lv/expiring-lcrs"
            title="Expiring LCRs"
            icon="notification_important"
            onClick={closeSidebar}
          />
        )}
        {canUse('SCHEDULE_DL') && (
          <MenuItem path="/hr/schedule-dl" title="Schedule DL" icon="directions_car" onClick={closeSidebar} />
        )}
        {canUse('SCREENSHOT_STATS') && (
          <MenuItem path="/lv/screenshot-stats" title="Screenshot Status" icon="fullscreen" onClick={closeSidebar} />
        )}
        {canUse('SCREENSHOT_STATS') && (
          <MenuItem
            path="/lv/monthly-screenshot-stats"
            title="Monthly Screenshot Status"
            icon="fullscreen"
            onClick={closeSidebar}
          />
        )}
        {canUse('SCREENSHOT_STATS') && (
          <MenuItem
            path="/lv/monthly-screenshot-history"
            title="Monthly Screenshot History"
            icon="fullscreen"
            onClick={closeSidebar}
          />
        )}
        {canUse('IMAGE_NOW') && <MenuItem path="/hr/image-now" title="Image Now" icon="image" onClick={closeSidebar} />}
      </Modal.Body>
      <div className="ec-monitoring-title-menu">Common Services</div>
      <Modal.Body>
        {canUse('GET_INVOICES') && (
          <MenuItem path="/payment" title="Payment" icon="local_atm" onClick={closeSidebar} active />
        )}
        {canUse('CREATE_EMPLOYER') && (
          <MenuItem path="/employer-creation" title="Employer creation" icon="domain_add" onClick={closeSidebar} />
        )}
      </Modal.Body>
      {canUse('WALLET_REG_STATUS') && (
        <>
          <div className="ec-monitoring-title-menu">EC Wallet</div>

          <Modal.Body>
            <MenuItem
              path="/wallet/registration-status"
              title="Wallet registration status"
              icon="account_balance_wallet"
              onClick={closeSidebar}
            />
          </Modal.Body>
        </>
      )}
      <div className="ec-monitoring-title-menu">Prehire</div>
      <Modal.Body>
        {canUse('RUN_PREHIRE_UPLOAD') && (
          <MenuItem
            path="/prehire/run-upload"
            title="Run applicants/positions upload"
            icon="arrow_upward"
            onClick={closeSidebar}
          />
        )}

        {canUse('GET_VERIFYING_CREDENTIALS') && (
          <MenuItem
            path="/prehire/verifying-credentials-summary"
            title="Verifying credentials summary"
            icon="card_membership"
            onClick={closeSidebar}
          />
        )}
      </Modal.Body>

      <div className="ec-monitoring-title-menu">HR</div>
      <Modal.Body>
        {canUse('RESET_HR_PASSWORD') && (
          <MenuItem path="/hr/reset-password" title="Reset password" icon="fingerprint" onClick={closeSidebar} />
        )}
        {canUse('REVIEW_LV_NOTIFICATIONS') && (
          <MenuItem
            path="/hr/audit-lv-notifications"
            title="Audit LV notifications"
            icon="fingerprint"
            onClick={closeSidebar}
          />
        )}
        {canUse('NOTIFICATION_LOGS') && (
          <MenuItem
            path="/hr/export-notifications-logs"
            title="Export notifications logs"
            icon="event_note"
            onClick={closeSidebar}
          />
        )}
        {canUse('NOTIFICATION_LOGS') && (
          <MenuItem
            path="/hr/custom-notification"
            title="Custom Notification Status"
            icon="event_note"
            onClick={closeSidebar}
          />
        )}
        {canUse('FILE_STATS') && (
          <MenuItem
            path="/hr/demographic-uploads-status"
            title="Demographics Upload Status"
            icon="file_upload"
            onClick={closeSidebar}
          />
        )}
      </Modal.Body>

      {canUse('DATABASE') && (
        <>
          <div className="ec-monitoring-title-menu">Database</div>

          <Modal.Body>
            <MenuItem
              path="/database/shareplex-status"
              title="Shareplex status"
              icon="storage"
              onClick={closeSidebar}
            />
            <MenuItem
              path="/database/oracle-packages"
              title="Oracle Packages status"
              icon="code"
              onClick={closeSidebar}
            />
            <MenuItem
              path="/database/oracle-email-notifications"
              title="Oracle Email Notifications"
              icon="email"
              onClick={closeSidebar}
            />
          </Modal.Body>
        </>
      )}

      {canUse('USER_MANAGEMENT') && (
        <>
          <div className="ec-monitoring-title-menu">User Management</div>

          <Modal.Body>
            <MenuItem path="/user/list" title="Users" icon="people" onClick={closeSidebar} />
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

export default sideBar;
