import axios from 'axios';
import moment from 'moment';

import { getAuthToken } from '../utils/auth';
import { EXPORT_EMAILS_LOG } from '../utils/constants';

export const lvAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getHeader = () => {
  return {
    headers: { Authorization: getAuthToken() },
  };
};

export const getEmployer = (params = {}) => {
  return lvAPI.get('/hr/employer', { params, ...getHeader() });
};

export const scheduleDriverLicense = (params = {}) => {
  return lvAPI.post('/hr/driver-license/schedule', params, { ...getHeader() });
};

export const getDriverLicenseHistory = (params = {}) => {
  return lvAPI.get('/hr/driver-license-history', { params, ...getHeader() });
};

export const lcrUploadProgress = (params = {}) => {
  return lvAPI.get('/hr/license-upload-progress', { params, ...getHeader() });
};

export const getlcrUploadProgressExport = async (params = {}) => {
  const { date } = params;
  const { data } = await lvAPI.get('hr/license-upload-progress-export', {
    params,
    ...getHeader(),
    responseType: 'arraybuffer',
  });

  const isFileEmpty = data.byteLength < 100;
  if (isFileEmpty) return;
  const dateFile = date ? moment(date, 'YYYY-MM-DD').format('MMDDYYYY') : moment().format('MMDDYYYY');

  const url = URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `LCR_Upload_report_${dateFile}.xlsx`);
  link.click();
  return true;
};

export const getUserAccount = (params = {}) => {
  return lvAPI.get(`/hr/user/${params.accountId}`, { ...getHeader() });
};

export const changeUserPassword = (accountId, params = {}) => {
  if (!accountId) Promise.reject('Account id required');

  return lvAPI.patch(`/hr/user/${accountId}`, params, { ...getHeader() });
};

export const getScheduledDriverLicense = async (params = {}) => {
  const { data } = await lvAPI.get('/hr/driver-license', {
    params,
    ...getHeader(),
    responseType: 'blob',
  });

  const url = URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'dl-sheduled.xlsx');
  link.click();
};

export const getImageNow = (params = {}) => {
  return lvAPI.get('/image-now', { params, ...getHeader() });
};
export const retryItemsFailed = (params = {}) => {
  return lvAPI.post('/image-now/retry', params, { ...getHeader() });
};

export const exportImageNow = (params = {}) => {
  return lvAPI.get('/image-now/export', {
    params,
    responseType: 'blob',
    ...getHeader(),
  });
};

export const getImageNowEmployers = (params = {}) => {
  return lvAPI.get('/image-now/employers', { params, ...getHeader() });
};

export const exportStatusProgress = (params = {}) => {
  return lvAPI.get('/hr/license-export-progress', { params, ...getHeader() });
};

export const exportStatusProgressDownload = async (params = {}) => {
  const { date } = params;
  const { data } = await lvAPI.get('/hr/license-export-progress-file', {
    params,
    ...getHeader(),
    responseType: 'arraybuffer',
  });

  const isFileEmpty = data.byteLength < 100;
  if (isFileEmpty) return;
  const dateFile = date ? moment(date, 'YYYY-MM-DD').format('MMDDYYYY') : moment().format('MMDDYYYY');

  const url = URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `Export_status_report_${dateFile}.xlsx`);
  link.click();
  return true;
};

export const downloadNotificationLogs = (params = {}) => {
  return lvAPI.get('/hr/notification-logs/export', {
    params,
    ...getHeader(),
    responseType: 'blob',
  });
};

export const downloadCustomNotificationLogs = (params = {}) => {
  return lvAPI.get('/custom-notifications/emails/file', {
    params,
    ...getHeader(),
    responseType: 'blob',
    timeout: EXPORT_EMAILS_LOG.TIME_OUT_EXPORT_EMAILS,
  });
};

export const enqueueCustomNotificationEmails = (emailsIds) => {
  return lvAPI.post(
    '/custom-notifications/emails/enqueue',
    { emailsIds },
    {
      ...getHeader(),
    }
  );
};

export const CustomNotificationProgress = (params = {}) => {
  return lvAPI.get('/custom-notifications/progress', { params, ...getHeader() });
};

export const createEmployer = (employerId) => {
  return lvAPI.post('/hr/employer', { employerId }, { ...getHeader() });
};

export const downloadFile = async (key, fileName) => {
  const { data } = await lvAPI.post('/hr/getFileLink', { key, fileName }, { ...getHeader() });
  window.open(data.signedUrl, '_self');
};

export const downloadOracleEmailNotifications = async (params) => {
  const response = await lvAPI.get('/hr/oracle-email-notifications', {
    params,
    ...getHeader(),
  });
  return response;
};

export const getDemographicUploadsStatus = (params = {}) => {
  return lvAPI.get('/hr/demographic-uploads-status', { params, ...getHeader() });
};
