import React from 'react';

import Card from 'emerald-ui/lib/Card';
import TextField from 'emerald-ui/lib/TextField';
import Button from 'emerald-ui/lib/Button';
import Toast from 'emerald-ui/lib/Toast';
import Container from 'emerald-ui/lib/Container';
import Panel from 'emerald-ui/lib/Panel';
import Col from 'emerald-ui/lib/Col';

import UploadHistories from './components/UploadHistory';
import ConformationModal from './components/ConformationModal';

import { getEmployer } from '../../api/hr';

class RunPrehireUpload extends React.Component {
  state = {
    employerId: '',
    showModal: false,
    employerInfo: undefined,
    showToast: false,
    toastMessage: '',
  };

  handleEmployerIdChange = (e) => {
    let employerId = e.target.value;

    this.setState({
      employerId,
    });
  };

  handleModal = async () => {
    try {
      this.setState({
        status: 'LOADING',
      });

      let { data: employerInfo } = await getEmployer({ employerId: this.state.employerId });

      let newState;

      if (employerInfo.length > 0) {
        newState = {
          employerInfo: employerInfo[0],
          showModal: true,
        };
      } else {
        newState = {
          showToast: true,
          toastMessage: 'Employer not found',
        };
      }

      this.setState({
        status: 'IDLE',
        ...newState,
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
        showToast: true,
        toastMessage: 'Something went wrong, API not responding',
      });
    }
  };

  afterSubmitCallback = (error) => {
    this.closeModal();
    this.setState({
      showToast: true,
      toastMessage: error ? 'Something went wrong :/' : 'Upload stared Sucessfully',
    });
  };

  hideSucessToast = () => {
    this.setState({
      showToast: false,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      employerId: '',
    });
  };

  render() {
    const employerIdValid = this.state.employerId.length > 2;
    const isLoading = this.state.status === 'LOADING';

    return (
      <Container>
        <Panel style={{ background: 'transparent', boxShadow: 'none' }}>
          <Col xs={6} style={{ margin: 'auto' }}>
            <Panel.Body>
              <Card style={{ width: '80%', margin: 'auto' }}>
                <TextField
                  label="Employer ID"
                  id="employer-id"
                  autoComplete="off"
                  onChange={this.handleEmployerIdChange}
                  value={this.state.employerId}
                  maxLength={3}
                />
                <Button
                  onClick={this.handleModal}
                  color="info"
                  disabled={!employerIdValid || isLoading}
                  loading={isLoading}
                >
                  <span role="img" aria-label="running forrest">
                    🏃🏽
                  </span>{' '}
                  RUN, FORREST, RUN!
                </Button>
              </Card>
            </Panel.Body>
          </Col>
        </Panel>

        <div style={{ width: '70%', margin: 'auto' }}>
          <br />
          <h2 className="text-dark" style={{ fontWeight: 'lighter' }}>
            Upload history
          </h2>
          <hr />
          <Panel style={{ background: 'transparent', boxShadow: 'none' }}>
            <Panel.Body style={{ background: 'transparent' }}>
              <UploadHistories />
            </Panel.Body>
          </Panel>
        </div>
        {/* This is the upload history table */}

        {/* Modal that pops up after click "run, forrest, run" button */}
        <ConformationModal
          show={this.state.showModal}
          onChange={this.handleEmployerIdChange}
          onClose={this.closeModal}
          employerInfo={this.state.employerInfo}
          runProcessCallback={this.afterSubmitCallback}
        />

        {this.state.showToast && (
          <Toast
            message={this.state.toastMessage}
            visible={this.state.showToast}
            onTimeout={this.hideSucessToast}
            position="right"
          />
        )}
      </Container>
    );
  }
}

export default RunPrehireUpload;
