import React from 'react';

import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import TextField from 'emerald-ui/lib/TextField';
import Button from 'emerald-ui/lib/Button';
import Alert from 'emerald-ui/lib/Alert';
import Spinner from 'emerald-ui/lib/Spinner';
import Card from 'emerald-ui/lib/Card';
import styled from 'styled-components';

import { checkCadidateStatus } from '../../api/wallet';

import DetailsModal from './components/DetailsModal';

const CardContainer = styled(Card)`
  width: 60%;
  margin: 10px auto;
`;

const Title = styled.h2`
  margin: 10px auto;
  font-weight: lighter;
`;

const TextFieldContainer = styled(Container)`
  margin: 20px 0;
`;

const SmallTextField = styled(TextField)``;
const ResetButton = styled(Button)`
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  margin: 15px;
  text-align: right;
`;

class WalletRegistrationStatus extends React.Component {
  state = {
    candidateId: '',
    candidateStatus: null,
    employerId: '',
    errorMessage: null,
    status: 'IDLE',
  };

  resetFields = () => {
    this.setState({
      candidateId: '',
      candidateStatus: null,
      employerId: '',
      errorMessage: '',
      status: 'IDLE',
    });
  };

  handleCanditeIdChange = (e) => {
    this.setState({
      candidateId: e.target.value,
    });
  };

  handleEmployerIdChange = (e) => {
    const eventValue = e.target.value;

    if (eventValue.match(/^[0-9]{0,3}$/)) {
      this.setState({
        employerId: eventValue,
      });
    }
  };

  handleSubmit = async () => {
    try {
      const { candidateId, employerId } = this.state;

      this.setState({
        status: 'LOADING',
        candidateStatus: null,
      });

      const { data: candidateStatus } = await checkCadidateStatus({
        candidateId,
        employerId,
      });

      if (candidateStatus.code === 'INVITE_NOT_FOUND') {
        return this.setState({
          errorMessage: 'Invite for candidate not found',
          status: 'ERRORED',
        });
      }

      this.setState({
        candidateStatus,
        errorMessage: '',
        status: 'IDLE',
      });
    } catch (error) {
      this.setState({
        errorMessage: 'Something went wrong, try again later or start crying',
        status: 'ERRORED',
      });
    }
  };

  render() {
    const { state } = this;

    const isLoading = state.status === 'LOADING';
    const isErrored = state.status === 'ERRORED';

    return (
      <Container>
        <CardContainer>
          <Title className="text-dark">Verify wallet accounts registration status</Title>
          <TextFieldContainer>
            <Row>
              <Col md={6}>
                <SmallTextField
                  label="Candidate ID"
                  id="candidate-id"
                  disabled={isLoading}
                  onChange={this.handleCanditeIdChange}
                  value={state.candidateId}
                />
              </Col>
              <Col md={6}>
                <SmallTextField
                  style={{ marginTop: 0 }}
                  label="Employer ID"
                  id="employer-id"
                  disabled={isLoading}
                  onChange={this.handleEmployerIdChange}
                  value={state.employerId}
                  maxLength={3}
                />
              </Col>
            </Row>
          </TextFieldContainer>
          <ButtonContainer>
            <ResetButton onClick={this.resetFields} disabled={isLoading}>
              RESET
            </ResetButton>
            <Button onClick={this.handleSubmit} color="info" loading={isLoading} disabled={isLoading}>
              SUBMIT
            </Button>
          </ButtonContainer>
          {isErrored ? (
            <Alert color="warning">
              <p style={{ padding: '10px 0', margin: 0 }}>{state.errorMessage}</p>
            </Alert>
          ) : null}
          {isLoading && <Spinner size="lg" style={{ margin: '20px auto' }} />}
          {state.candidateStatus && <DetailsModal {...state.candidateStatus} />}
        </CardContainer>
      </Container>
    );
  }
}

export default WalletRegistrationStatus;
