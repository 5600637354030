import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Appbar from './components/Appbar';
import BoardStatsDashboard from './scenes/BoardStatsDashboard';
import FileStartsDashboard from './scenes/FileStatus';
import LcrUploadStatus from './scenes/LcrUploadStatus';
import LoginModal from './scenes/Login';
import RunPrehireUpload from './scenes/RunPrehireUpload';
import Sidebar from './components/Sidebar';
import StateStatsDashboard from './scenes/StateStatsDashboard';
import ScheduleDriverLicenses from './scenes/ScheduleDriverLicenses';
import ChangeHRPassword from './scenes/ChangeHRPassword';
import ShareplexStatus from './scenes/ShareplexStatus';
import OraclePackagesStatus from './scenes/OraclePackagesStatus';
import ScreenshotStatus from './scenes/ScreenshotStatus';
import MonthlyScreenshotStatus from './scenes/MonthlyScreenshotStatus';
import AuditLvNotifications from './scenes/AuditLvNotifications';
import ImageNowDashboard from './scenes/ImageNowDashBoard';
import { isUserLogged, getTokenPayload } from './utils/auth';
import ExportStatus from './scenes/ExportStatus';
import Payment from './scenes/Payment';
import ExpiringLCRs from './scenes/ExpiringLCRs';

import { canUse } from './utils/userPermissionHandler';

import LogoutHandler from './scenes/Logout';
import WalletRegistrationStatus from './scenes/WalletRegistrationStatus';
import VerifyingCredentialsSummary from './scenes/VerifyingCredentialsSummary';
import MonthlyScreenshotHistory from './scenes/MonthlyScreenshotHistory';
import NotificationLogs from './scenes/NotificationLogs';
import UserManagement from './scenes/UserManagement';
import CustomNotificationStatus from './scenes/CustomNotificationStatus';
import OracleEmailNotifications from './scenes/OracleEmailNotifications';
import EmployerCreation from './scenes/EmployerCreation';
import DemographicUploadsStatus from './scenes/DemographicUploadsStatus';

import firebase from 'firebase/app';
import 'firebase/firestore';

import 'emerald-ui/lib/styles-ec.css';
import './App.css';

import SessionContext from './contexts/sessionContext';

const fireBaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  projectId: process.env.REACT_APP_FIREBASE_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(fireBaseConfig);
} else {
  firebase.app();
}

const Container = styled.div`
  margin: 30px auto;
`;

class LoggedFrame extends Component {
  constructor(props) {
    super(props);

    //tokenPayload represents the information of the currently logged user
    const TokenPayload = getTokenPayload();

    this.state = {
      showSidebar: false,
      userName: TokenPayload ? TokenPayload.name : 'Unkown',
    };
  }

  openSidebar = () => {
    this.setState({ showSidebar: true });
  };

  closeSidebar = () => {
    this.setState({ showSidebar: false });
  };

  render() {
    return (
      <React.Fragment>
        <Appbar openSidebar={this.openSidebar} userName={this.state.userName} />
        <Sidebar show={this.state.showSidebar} closeSidebar={this.closeSidebar} />

        <Container>
          <Switch>
            {canUse('STATE_STATS') && <Route exact path="/lv/state-stats" component={StateStatsDashboard} />}
            {canUse('SCREENSHOT_STATS') && <Route exact path="/lv/screenshot-stats" component={ScreenshotStatus} />}
            {canUse('SCREENSHOT_STATS') && (
              <Route exact path="/lv/monthly-screenshot-stats" component={MonthlyScreenshotStatus} />
            )}
            {canUse('SCREENSHOT_STATS') && (
              <Route exact path="/lv/monthly-screenshot-history" component={MonthlyScreenshotHistory} />
            )}
            {canUse('BOARD_STATS') && <Route exact path="/lv/board-stats" component={BoardStatsDashboard} />}
            {canUse('FILE_STATS') && <Route exact path="/lv/file-stats" component={FileStartsDashboard} />}
            {canUse('IMAGE_NOW') && <Route exact path="/hr/image-now" component={ImageNowDashboard} />}
            {canUse('LICENSE_UPLOAD_PROGRESS') && (
              <Route exact path="/lv/license-uploads" component={LcrUploadStatus} />
            )}
            {canUse('LICENSE_EXPORT_PROGRESS') && <Route exact path="/lv/export-stats" component={ExportStatus} />}
            {canUse('EXPIRING_LICENSES') && <Route exact path="/lv/expiring-lcrs" component={ExpiringLCRs} />}
            {canUse('GET_INVOICES') && <Route exact path="/payment" component={Payment} />}
            {canUse('CREATE_EMPLOYER') && <Route exact path="/employer-creation" component={EmployerCreation} />}
            {canUse('SCHEDULE_DL') && <Route exact path="/hr/schedule-dl" component={ScheduleDriverLicenses} />}
            {canUse('RESET_HR_PASSWORD') && <Route exact path="/hr/reset-password" component={ChangeHRPassword} />}
            {canUse('WALLET_REG_STATUS') && (
              <Route exact path="/wallet/registration-status" component={WalletRegistrationStatus} />
            )}
            {canUse('RUN_PREHIRE_UPLOAD') && <Route exact path="/prehire/run-upload" component={RunPrehireUpload} />}
            {canUse('GET_VERIFYING_CREDENTIALS') && (
              <Route exact path="/prehire/verifying-credentials-summary" component={VerifyingCredentialsSummary} />
            )}
            {canUse('DATABASE') && <Route exact path="/database/shareplex-status" component={ShareplexStatus} />}
            {canUse('DATABASE') && <Route exact path="/database/oracle-packages" component={OraclePackagesStatus} />}
            {canUse('DATABASE') && (
              <Route exact path="/database/oracle-email-notifications" component={OracleEmailNotifications} />
            )}
            {canUse('REVIEW_LV_NOTIFICATIONS') && (
              <Route exact path="/hr/audit-lv-notifications" component={AuditLvNotifications} />
            )}
            {canUse('NOTIFICATION_LOGS') && (
              <Route exact path="/hr/export-notifications-logs" component={NotificationLogs} />
            )}
            {canUse('USER_MANAGEMENT') && <Route exact path="/user/list" component={UserManagement} />}
            {canUse('NOTIFICATION_LOGS') && (
              <Route exact path="/hr/custom-notification" component={CustomNotificationStatus} />
            )}
            {canUse('FILE_STATS') && (
              <Route exact path="/hr/demographic-uploads-status" component={DemographicUploadsStatus} />
            )}

            <Route exact path="/logout" component={LogoutHandler} />

            <Redirect to="/lv/state-stats" />
          </Switch>
        </Container>
      </React.Fragment>
    );
  }
}

const App = () => {
  const [userLogged, setUserLogged] = React.useState(isUserLogged());
  if (!userLogged) {
    return (
      <SessionContext.Provider
        value={{
          isLogged: userLogged,
          updateIsLogged: setUserLogged,
        }}
      >
        <Switch>
          <Route exact path="/login" component={LoginModal} />
          <Redirect to="/login" />
        </Switch>
      </SessionContext.Provider>
    );
  } else {
    return (
      <SessionContext.Provider
        value={{
          isLogged: userLogged,
          updateIsLogged: setUserLogged,
        }}
      >
        <Switch>
          <Route path="/" component={LoggedFrame} />
        </Switch>
      </SessionContext.Provider>
    );
  }
};

export default App;
